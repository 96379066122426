var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"document-status"},[(_vm.canHaveStages && _vm.ds)?_c('div',{staticClass:"stages"},[_vm._m(0),_c('section',{class:{ waiting: !_vm.ds.two, signed: _vm.ds.two }},[_c('span',{staticClass:"stage-line"}),_c('span',{staticClass:"icon"},[_vm._v(_vm._s(`]`))])]),_c('section',{class:{
        waiting: _vm.ds.three.w,
        signed: _vm.ds.three.s,
      }},[_c('span',{staticClass:"stage-line"}),_c('span',{staticClass:"icon"},[_vm._v("^")])]),(_vm.ds.four)?_c('section',{class:{
        waiting: _vm.ds.four.w,
        signed: _vm.ds.four.s,
      }},[_c('span',{staticClass:"stage-line"}),_c('span',{staticClass:"icon"},[_vm._v("`")])]):_vm._e()]):_vm._e(),_c('div',{class:`status status--${_vm.type}`},[_c('p',[(_vm.d.annulled)?[_vm._v("АНУЛИРАН")]:(_vm.type === 'approved')?[_vm._v("ЗАВЪРШЕН ДОКУМЕНТ")]:(_vm.type === 'waiting')?[_vm._v(_vm._s(_vm.state))]:(_vm.type === 'not-approved')?[_vm._v("НЕОДОБРЕН")]:(_vm.type === 'used-as-parent')?[_vm._v("ГЕНЕРИРАНО ДС")]:(_vm.type === 'used-as-parent-card')?[_vm._v("ГЕНЕРИРАНО ЗАЯВЛЕНИЕ")]:_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"signed"},[_c('span',{staticClass:"icon"},[_vm._v("\\")])])
}]

export { render, staticRenderFns }