import Vue from 'vue'
import Vuex from 'vuex'
import error from './error.module'
import sidepanel from './sidepanel.module'
import auth from './auth.module'
import company from './company.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    error,
    sidepanel,
    auth,
    company
  }
})