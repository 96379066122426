<template>
  <button
    class="button"
    :class="type ? `button--${type}` : null"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['type'],
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.button {
  align-items: center;
  background-color: #dfdfdf;
  border-radius: 2px;
  border: 0;
  display: inline-flex;
  font-size: $px12;
  height: 30px;
  justify-content: center;
  outline: none;
  width: 146px;
  @include noselect();
  @include transition(background-color $duration $cubic);

  .icon {
    font-size: 1.8rem;
    display: inline-block;
    margin-right: 0.7rem;
  }

  &.button--big {
    height: 46px;
    min-height: 46px;
    width: 330px;
  }

  &:active {
    @include transform(scale(0.98));
  }
  &:disabled {
    opacity: 0.5;
  }
  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      background-color: #ececec;
    }
  }

  &.button--blue {
    background-color: $lightBlue;
    color: #fff;
    &:not(:disabled):hover {
      background-color: $lightAccent;
    }
  }

  &.button--darker-blue {
    background-color: #006fc2;
    color: #fff;
    &:not(:disabled):hover {
      background-color: #1987db;
    }
  }

  &.button--red {
    background-color: $darkRed;
    color: #fff;
    &:not(:disabled):hover {
      background-color: $lightRed;
    }
  }

  &.button--orange {
    background-color: $darkOrange;
    color: #fff;
    &:not(:disabled):hover {
      background-color: $lightOrange;
    }
  }

  &.button--grey {
    background-color: $darkGrey;
    color: #fff;
    &:not(:disabled):hover {
      background-color: $lightGrey;
    }
  }

  &.button--green {
    background-color: $darkGreen;
    color: #fff;
    &:not(:disabled):hover {
      background-color: $lightGreen;
    }
  }

  &.button--lighter-green {
    background-color: #00bc19;
    color: #fff;
    &:not(:disabled):hover {
      background-color: #0edd2a;
    }
  }

  &.button--draft {
    background-color: #ababab;
    color: #fff;
    &:not(:disabled):hover {
      background-color: #c7c7c7;
    }
  }

  &.button--dark {
    background-color: #303030;
    color: #fff;
    &:not(:disabled):hover {
      background-color: #3f3f3f;
    }
  }
}

@media screen and (max-width: $xxs) {
  .button {
    &.button--big {
      width: 260px;
    }
  }
}
</style>
